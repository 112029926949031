var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "proxy", staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-md-12 col-lg-12" },
      [
        _c(
          "c-card",
          {
            staticClass: "cardClassDetailInfo",
            attrs: { title: "서명보기", topClass: "topcolor-orange" },
          },
          [
            _c("template", { slot: "card-detail" }, [
              _c(
                "div",
                { staticClass: "col-xs-12 col-sm-12" },
                [
                  _c("VueSignaturePad", {
                    ref: "signaturePad",
                    attrs: { width: "200px", height: "200px" },
                  }),
                ],
                1
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }