<template>
  <div class="row" ref="proxy">
    <div class="col-md-12 col-lg-12">
      <c-card title="서명보기" class="cardClassDetailInfo" topClass="topcolor-orange">
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12">
            <VueSignaturePad width="200px" height="200px" ref="signaturePad" />
          </div>
        </template>
      </c-card>
    </div>
  </div>
</template>
<script>
import VueSignaturePad from 'vue-signature-pad';
import Vue from 'vue';
let signaturePad = null;
Vue.use(VueSignaturePad)
export default {
  name: 'signature-pop',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          electronSignature: '',
        }
      },
    },
    tabParam: {
      type: Object,
      default(){
        return {
          documnetStatusCd: '',
        }
      },
    },
    rowIndex: {
      type: Number,
      default: 0,
    },
    outFlag: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      if (!this.$refs.signaturePad) {
        return;
      }
      this.windowWidth = (document.documentElement.clientWidth - 45) + 'px';
      signaturePad = this.$refs.signaturePad

      // if(this.tabParam.documnetStatusCd === 'ESC000010') 
      signaturePad.lockSignaturePad();

      if (this.popupParam.electronSignature) {
        signaturePad.fromDataURL(this.popupParam.electronSignature);
      }
    },
  }
};
</script>